export const smallcases = [
  {
    name: "All Weather Investing",
    description: "One investment for all market conditions. Works for everyone",
    by: "Windmill Capital",
    minAmount: 3033,
    cagr: [
      { time: "1M", cagr: 12.68 },
      { time: "6M", cagr: 10.5 },
      { time: "1Y", cagr: 9.3 },
      { time: "3Y", cagr: 11.2 },
      { time: "5Y", cagr: 12.0 },
    ],
    volatility: "Low",
    freeAccess: true,
    icon: "https://assets.smallcase.com/images/smallcases/160/SCAW_0001.png",
  },
  {
    name: "Top 100 Stocks",
    description:
      "India's most powerful companies in one portfolio. Solid stability",
    by: "Windmill Capital",
    minAmount: 2668,
    cagr: [
      { time: "1M", cagr: 15.0 },
      { time: "6M", cagr: 16.3 },
      { time: "1Y", cagr: 14.5 },
      { time: "3Y", cagr: 16.8 },
      { time: "5Y", cagr: 15.2 },
    ],
    volatility: "Med",
    freeAccess: true,
    icon: "https://assets.smallcase.com/images/smallcases/160/SCET_0004.png",
  },
  {
    name: "Equity & Gold",
    description:
      "Create wealth with equities, stay protected with Gold. The sweet spot.",
    by: "Windmill Capital",
    minAmount: 388,
    cagr: [
      { time: "1M", cagr: 13.2 },
      { time: "6M", cagr: 12.9 },
      { time: "1Y", cagr: 13.8 },
      { time: "3Y", cagr: 14.1 },
      { time: "5Y", cagr: 14.7 },
    ],
    volatility: "Low",
    freeAccess: true,
    icon: "https://assets.smallcase.com/images/smallcases/160/SCET_0005.png",
  },
  {
    name: "CANSLIM-esque",
    description:
      "Efficiently managed growing companies experiencing positive momentum, screened using...",
    by: "Windmill Capital",
    minAmount: 103605,
    cagr: [
      { time: "1M", cagr: 20.0 },
      { time: "6M", cagr: 22.0 },
      { time: "1Y", cagr: 23.5 },
      { time: "3Y", cagr: 24.5 },
      { time: "5Y", cagr: 25.0 },
    ],
    volatility: "High",
    freeAccess: false,
    icon: "https://assets.smallcase.com/images/smallcases/160/SCMO_0029.png",
  },
  {
    name: "Green Energy",
    description:
      "A portfolio of stocks, which will get benefit from the Renewable Energy sector development.",
    by: "Niveshaay",
    minAmount: 114045,
    cagr: [
      { time: "1M", cagr: 65.0 },
      { time: "6M", cagr: 68.0 },
      { time: "1Y", cagr: 70.0 },
      { time: "3Y", cagr: 71.0 },
      { time: "5Y", cagr: 72.0 },
    ],
    volatility: "High",
    freeAccess: false,
    icon: "https://assets.smallcase.com/images/smallcases/160/NIVTR_0001.png",
  },
  {
    name: "NI_NNF10 Momentum",
    description:
      "Strongest top 10 stocks from the Nifty Next50 Index | Rotates into trending Sectors | Non Rebalanced",
    by: "Weekend Investing",
    minAmount: 53453,
    cagr: [
      { time: "1M", cagr: 30.0 },
      { time: "6M", cagr: 31.5 },
      { time: "1Y", cagr: 32.0 },
      { time: "3Y", cagr: 33.0 },
      { time: "5Y", cagr: 34.0 },
    ],
    volatility: "High",
    freeAccess: false,
    icon: "https://assets.smallcase.com/images/smallcases/160/WKIMO_0009.png",
  },
  {
    name: "The Great Indian Middle Class",
    description:
      "Companies focused on the growing middle class. Running India's consumption engine",
    by: "Windmill Capital",
    minAmount: 59075,
    cagr: [
      { time: "1M", cagr: 10.0 },
      { time: "6M", cagr: 10.5 },
      { time: "1Y", cagr: 11.0 },
      { time: "3Y", cagr: 11.2 },
      { time: "5Y", cagr: 11.5 },
    ],
    volatility: "Med",
    freeAccess: true,
    icon: "https://assets.smallcase.com/images/smallcases/160/SCNM_0010.png",
  },
  {
    name: "Wright Momentum",
    description:
      "Momentum investing: Aiming to capitalize on market trends for potential growth.",
    by: "Wright Research",
    minAmount: 76130,
    cagr: [
      { time: "1M", cagr: 30.0 },
      { time: "6M", cagr: 31.0 },
      { time: "1Y", cagr: 32.0 },
      { time: "3Y", cagr: 32.5 },
      { time: "5Y", cagr: 33.0 },
    ],
    volatility: "High",
    freeAccess: false,
    icon: "https://assets.smallcase.com/images/smallcases/160/WRTNM_0001.png",
  },
];

export const strategies = [
  "Asset Allocation",
  "Corporate Governance",
  "Dividend",
  "ESG",
  "Factor Investing",
  "Fundamental",
  "Goal Based",
  "Growth",
  "Momentum",
  "Quality",
  "Quantamental",
  "Quantitative",
  "Sector Tracker",
  "Technical",
  "Thematic",
  "Value",
];
